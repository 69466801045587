/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  background: #f5f5f5;
  margin: 0;
  padding: 0;
}

header {
  background-color: #343a40;
  color: white;
  padding: 1rem 0;
  text-align: center;
}

nav {
  background-color: #f8f9fa;
}

nav a {
  color: #007bff;
  text-decoration: none;
  margin: 0 15px;
}

nav a:hover {
  color: #0056b3;
  text-decoration: underline;
}

main {
  padding: 2rem;
}

footer {
  background-color: #343a40;
  color: white;
  text-align: center;
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  bottom: 0;
}

/* Home Page Styles */
.home-container {
  background-color: #007bff;
  color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* About Page Styles */
.about-container {
  background-color: #6c757d;
  color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}
.about-img {
  padding: 2rem;
  border-radius: 10px;
  max-width: 300px;
  height: auto;
  animation: fadeIn 1s ease-in-out;
}

/* Activities Page Styles */
.activities-container {
  background-color: #28a745;
  color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

.hamradio-container {
  background-color: #17b885;
  color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Weather Spotting Page Styles */
.weather-container {
  background-color: #17a2b8;
  color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Contact Page Styles */
.contact-container {
  background-color: #ffc107;
  color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* AboutJordan Page Styles */
.development-container {
  background-color: #17a2b8;
  color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

.development-container h2, .development-container h3 {
  color: #fff;
}

.development-container a {
  color: #ffc107;
  text-decoration: none;
}

.development-container a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Button Styles */
button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 0.25rem 0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Form Styles */
form {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form .form-group {
  margin-bottom: 1rem;
}

form .form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

form .form-group input,
form .form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form .form-group textarea {
  resize: vertical;
}

.nav-img {
  max-height: 50px;
  height: auto;
  border: 4px solid black;
  border-radius: 15px;
}

.card {
  margin: 1rem 0;
}

.card img {
  height: 200px;
  object-fit: cover;
}

.card-title {
  font-size: 1.5rem;
  color: #007bff;
}

.card-text {
  font-size: 1rem;
  color: #333;
}

.btn-block {
  width: 100%;
}

.hover-pointer {
  cursor: pointer;
}